import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-expandible-modal',
  templateUrl: './expandible-modal.component.html',
  styleUrl: './expandible-modal.component.scss'
})
export class ExpandibleModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ExpandibleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close(): void {
    this.dialogRef.close({ action: 'close' });
  }

  saveAndClose(): void {
    this.dialogRef.close({ action: 'update', value: this.data.text });
  }
}

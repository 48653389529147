import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { decodeJWT } from 'aws-amplify/auth';
import { HttpClient } from '@angular/common/http';
import { UAMAuthService } from '../services/uam-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {
  idToken: string;
  refreshToken: string;
  constructor(
    private authService: UAMAuthService,
    private _httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.idToken = authService.getIdToken();
    this.refreshToken = authService.getRefreshToken();
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const endPoint = environment.endpoint;
    const excludedUrls = [
      `${endPoint}auth/settings`,
      '/assets/template/colors.json',
      `https://dev-osd-warfighter-export-data-bucket.s3.us-gov-west-1.amazonaws.com`,
      `https://qa-osd-warfighter-export-data-bucket.s3.us-gov-west-1.amazonaws.com`,
      `https://preprod-osd-warfighter-export-data-bucket.s3.us-gov-west-1.amazonaws.com`,
      `https://prod-osd-warfighter-export-data-bucket.s3.us-gov-west-1.amazonaws.com`,
      `https://${environment.uploadBucketEnv}-osd-warfighter-export-data-bucket.s3.us-gov-west-1.amazonaws.com`,
      `https://prod-osd-warfighter-export-data-bucket.s3.us-gov-west-1.amazonaws.com`,
    ];
    const refreshUrl = [`${environment.refreshEndpoint}`];
    let authReq: any;
    if (refreshUrl.some((x) => req.url.includes(x))) {
      //do nothing re-requesting auth
      authReq = req.clone();
      return next.handle(authReq);
    } else {
      if (excludedUrls.some((x) => req.url.includes(x))) {
        authReq = req.clone();
        return next.handle(authReq);
      } else {
        //Start 23- Add the current token to response
        const decodedToken = decodeJWT(this.idToken);
        const tokenExpTime = decodedToken.payload.exp as number;
        const currTime = Math.floor(Date.now() / 1000);
        if (currTime > tokenExpTime) {
          //expired
          return this.authService.refreshTokenFunction(this.refreshToken).pipe(
            catchError((error) => {
              if (error.error.errorMessage === 'refresh expired') {
                const redirectUrl = environment.url.replace(/https?:\/\//i, '');
                setTimeout(() => {
                  this._snackBar
                    .open('Session expired, login required.', 'OK', {
                      duration: 5000,
                    })
                    .afterDismissed()
                    .subscribe(() => {
                      this.document.location.href =
                        environment.uamUrl + 'auth/login/' + redirectUrl;
                    });
                }, 2000);
              }
              return of(new Error('erorr refreshing token'));
            }),
            tap({
              next: (response: any) => {
                this.idToken = response.response.AuthenticationResult.IdToken;
              },
              error: (error) => {
                // console.log("error on tp,", error)
              },
            }),
            switchMap((ree) => {
              const authReq = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.idToken}`,
                },
              });
              return next.handle(authReq);
            })
          );
        } else {
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${this.idToken}`,
            },
          });
          //Do not need refresh. use token from memory
          return next.handle(authReq);
        }
      }
    }
  }
}

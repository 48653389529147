<div [ngClass]="{'pointer-events-none opacity-50': disabled}" appScrollbar>
  <table [style.min-width.px]="adjustedWidth"
    class="min-w-full bg-gray-50 text-gray-700 uppercase text-xs font-bold border-l border-b border-t border-gray-300 relative">
    <thead>
      <tr class="bg-gray-100">
        <ng-container *ngFor="let field of table.fields; let colIndex = index">
          <th class="text-center py-1 border-r border-gray-300" [ngStyle]="{
                'width': (field?.size || 100) + 'px',
                'position': shouldBeSticky(colIndex) ? 'sticky' : 'initial',
                'left': getStickyPosition(colIndex),
                'z-index': '2',
                'background': 'rgb(243 244 246)'
              }">
            {{ field.name }}
          </th>
        </ng-container>
        <th *ngIf="field?.metadata?.allowAddRows && table.rows.length > defaultRowTotals"
          class="text-center py-1 border-r border-gray-300" style="width: 20px;">
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of table.rows; trackBy: trackByFn; let i = index">
        <ng-container *ngFor="let field of table.fields; trackBy: trackByFn; let colIndex = index">
          <td [ngClass]="{'bg-gray-100': !field.editable, 'bg-white': field.editable}" [ngStyle]="{
                'width': (field?.size || 100) + 'px',
                'max-width': (field?.size || 100) + 'px',
                'position': shouldBeSticky(colIndex) ? 'sticky' : 'initial',
                'left': getStickyPosition(colIndex),
                'z-index': '1',
                'background': !field.editable ? 'rgb(243 244 246)' : 'white'
              }" class="border border-gray-300 overflow-hidden whitespace-nowrap">
            <div *ngIf="field.editable" class="py-1 px-2 relative w-full flex overflow-hidden">
              <input [(ngModel)]="row[field.uuid]" (ngModelChange)="emitDataChange()"
                (keydown)="field.data_type === 'numeric' ? onNumericInput($event, row, field) : true"
                (paste)="field.data_type === 'numeric' ? onPaste($event, row, field) : onAlphanumericPaste($event, row, field)"
                (blur)="field.data_type === 'numeric' ? onNumericBlur($event, row, field) : null"
                (input)="onInputChange($event, row, field)" [maxlength]="getMaxLength(field)"
                class="w-full bg-transparent border-none focus:outline-none flex-1 overflow-hidden text-ellipsis whitespace-nowrap"
                style="min-width: 0" [disabled]="disabled" [type]="field.data_type === 'numeric' ? 'text' : 'text'"
                [mask]="field.data_type === 'numeric' ? 'separator.2' : ''" [thousandSeparator]="','"
                placeholder="Enter value">
              <button *ngIf="field.editable && expandable" class="flex-shrink-0 px-2 flex items-center h-full"
                (click)="openModal(field.uuid, row, field.name, i)">
                <lucide-icon name="maximize-2" class="w-4 h-4"></lucide-icon>
              </button>
            </div>
            <span *ngIf="!field.editable" class="py-1 px-2 block overflow-hidden text-ellipsis whitespace-nowrap">
              {{ row[field.uuid] }}
            </span>
          </td>
        </ng-container>

        <td *ngIf="field?.metadata?.allowAddRows && table.rows.length > defaultRowTotals"
          class="border border-gray-300 text-center p-0" style="width: 20px;">
          <button *ngIf="i + 1 > defaultRowTotals" (click)="deleteRow(i)" class="text-red-500 p-0 m-0">
            <lucide-icon name="trash-2" class="w-4 h-4"></lucide-icon>
          </button>
        </td>

      </tr>

      <tr *ngIf="shouldShowTotalsRow()" class="bg-gray-200 font-bold border-t-2 border-t-gray-300">
        <td *ngFor="let field of table.fields; let colIndex = index" [ngStyle]="{
              'width': (field?.size || 100) + 'px',
              'position': shouldBeSticky(colIndex) ? 'sticky' : 'initial',
              'left': getStickyPosition(colIndex),
              'z-index': '1',
              'background': 'rgb(229 231 235)'
            }" class="border border-gray-300">
          <div class="py-1 px-2">
            <ng-container *ngIf="field.data_type === 'numeric' && field.enable_totals">
              <span class="font-bold text-gray-700">{{ formatNumber(columnTotals[field.uuid]) }}</span>
            </ng-container>
            <ng-container *ngIf="!field.enable_totals && field === table.fields[0]">
              <span class="font-bold text-gray-700">Total</span>
            </ng-container>
          </div>
        </td>
        <td *ngIf="field?.metadata?.allowAddRows && table.rows.length > defaultRowTotals" [ngStyle]="{
              'width': '20px',
              'position': 'sticky',
              'right': '0',
              'z-index': '1',
              'background': 'rgb(229 231 235)'
            }" class="border border-gray-300">
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="mt-4 flex justify-start" *ngIf="field?.metadata?.allowAddRows || false">
  <button (click)="addRow()" class="primary-button flex flex-row text-xs" [disabled]="disabled">
    <lucide-angular name="plus" class="h-4 w-4"></lucide-angular>
    <span>Add Row</span>
  </button>
</div>

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionHelper } from 'src/app/shared/helpers/subscription.helper';
import { UAMAuthService } from 'src/app/shared/services/uam-auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements AfterViewInit, OnDestroy {
  @Input() isSlimMode = true;
  userHaveRole = false;
  isAdmin = false;
  currentUser: any = {};

  navigation = [
    {
      name: 'Home',
      icon: 'home',
      routes: ['/admin'],
      roles: ['organization-admin', 'organization-user', 'base'],
    },
    {
      name: 'Surveys Builder',
      icon: 'clipboard-list',
      routes: ['/admin/surveys', '/admin/survey'],
      roles: ['organization-admin'],
    },
    {
      name: 'Forms Builder',
      icon: 'hammer',
      routes: ['/admin/form-builder', '/admin/form-editor'],
      roles: ['organization-admin'],
    },
    {
      name: 'Artifacts Search',
      icon: 'search',
      routes: ['/admin/search-artifacts'],
      roles: ['organization-admin', 'organization-user'],
    },
    {
      name: 'Forms Data Lookup',
      icon: 'clipboard-type',
      routes: ['/admin/forms-data-lookup'],
      roles: ['organization-admin', 'organization-user'],
    },
    {
      name: 'Assignments',
      icon: 'shapes',
      routes: ['/admin/assignments', '/admin/assignments/workarea'],
      roles: ['organization-admin', 'base'],
    },
    {
      name: 'Sessions',
      icon: 'calendar-days',
      routes: ['/admin/sessions'],
      roles: ['organization-admin'],
    },
    {
      name: 'Invite List',
      icon: 'contact',
      routes: ['/admin/invitations'],
      roles: ['organization-admin'],
    },
    {
      name: 'Insights',
      icon: 'area-chart',
      routes: ['/admin/results'],
      roles: ['organization-admin'],
    },
  ];

  constructor(
    private subMgr: SubscriptionHelper,
    private router: Router,
    private uamService: UAMAuthService,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnDestroy(): void {
    this.subMgr.destroy();
  }
  ngAfterViewInit(): void { }

  ngOnInit() {
    const userDetails: any = this.uamService.getAuthenticatedUser();
    this.currentUser = userDetails;
    if (this.currentUser) {
      if (
        this.currentUser['custom:warfighter_role'] === 'organization-admin' ||
        this.currentUser['custom:warfighter_role'] === 'admin'
      ) {
        this.isAdmin = true;
      }
      this.cdr.detectChanges();
    }
  }

  isActive(paths: string[]): boolean {
    if (paths[0] === '/admin') {
      return false;
    }
    return paths.some((path) => this.router.url.includes(path));
  }
  isModuleDisabled() {
    return !this.userHaveRole && !this.isAdmin;
  }
  onNavigationClick(nav: any) {
    if (nav.routes) {
      this.router.navigate([nav.routes[0]]);
    }
  }
  haveAccess(nav: any) {
    if (nav.roles) {
      return nav.roles.includes(this.currentUser['custom:warfighter_role']);
    }
    return false;
  }
}

<div class="modal-header flex items-center space-x-4 px-5 py-4 bg-gray-50 border-b border-gray-200">
  <div class="text-lg font-medium text-gray-700">Settings: {{ tableField.name }}</div>
</div>

<div class="modal-body px-4 pt-4 pb-4 bg-white">

  <div class="flex flex-col gap-3">



    <div class="flex flex-col gap-1">
      <label class="text-sm text-gray-600">Field Mode</label>
      <select [(ngModel)]="tableField.editable"
        (ngModelChange)="tableField.editable = $event === 'true' ? true : false;"
        class="border border-gray-300 rounded p-2">
        <option [value]="true">Editable</option>
        <option [value]="false">Read-only</option>
      </select>
    </div>

    <div class="flex flex-col gap-1">
      <label class="text-sm text-gray-600">Data Type</label>
      <select [(ngModel)]="tableField.data_type" class="border border-gray-300 rounded p-2">
        <option value="alphanumeric">Alphanumeric</option>
        <option value="numeric">Numeric</option>
      </select>
    </div>

    <div class="flex flex-col gap-1" *ngIf="tableField.data_type === 'alphanumeric'">
      <label class="text-sm text-gray-600">Max Length</label>
      <input type="number" [(ngModel)]="tableField.max_characters"
        class="flex-grow bg-transparent border border-gray-300 p-1 rounded" min="1" value="50">
      <small>Maximum number of characters allowed in this field</small>
    </div>

    <div class="flex flex-col gap-1" *ngIf="tableField.data_type === 'numeric'">
      <label class="text-sm text-gray-600">Column Totals</label>
      <select [(ngModel)]="tableField.enable_totals" class="border border-gray-300 rounded p-2">
        <option [value]="false">Disabled</option>
        <option [value]="true">Enabled</option>
      </select>
      <small>Enable to show column totals at the bottom of the table</small>
    </div>

    <div class="flex flex-col gap-1">
      <label class="text-sm text-gray-600">Column Size</label>
      <input type="text" [(ngModel)]="tableField.size"
        class="flex-grow bg-transparent border border-gray-300 p-1 rounded">
      <small>Enter the table size in pixels as an integer, e.g., '100'.</small>
    </div>
  </div>




</div>


<div class="modal-footer flex justify-end space-x-3 p-5 bg-white border-t border-gray-200">
  <!-- <button class="bg-blue-500 text-white rounded hover:bg-blue-600 px-5 py-2 focus:outline-none" (click)="close()"
    type="button" aria-label="Close settings">
    Close
  </button> -->

  <button type="button" (click)="close()" class="primary-button flex flex-row text-xs focus:outline-none"
    aria-label="Close settings">
    <lucide-angular name="x" class="h-4 w-4"></lucide-angular>
    <span>Close</span>
  </button>
</div>

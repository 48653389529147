import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }
    const date = new Date(value * 1000);
    if (isNaN(date.getTime())) {
      return '';
    } else {
      return date.toLocaleDateString();
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarStateService {
  private sidebarStateSubject = new BehaviorSubject<'open' | 'closed' | null>(
    null
  );
  sidebarState$ = this.sidebarStateSubject.asObservable();

  setSidebarState(state: 'open' | 'closed') {
    this.sidebarStateSubject.next(state);
  }
}

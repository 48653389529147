import { ChangeDetectorRef, Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { AppUserService } from 'src/app/shared/services/app-user.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState, AppUserState } from 'src/app/shared/store/states/app.state';
import { select, Store } from '@ngrx/store';
import { SubscriptionHelper } from 'src/app/shared/helpers/subscription.helper';
import { selectCurrentUser } from 'src/app/shared/store/selectors/user.selector';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { UAMAuthService } from 'src/app/shared/services/uam-auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() title1 = environment.application.admin.title1;
  @Input() title2 = environment.application.admin.title2;
  isDevEnvironment = false;
  unreadNotifications = 6;
  notificationIcon = 'notifications_none';
  isAdminRoute = false;
  isAuthRoute = false;
  isRegistrationRoute = false;
  currentUser!: AppUserState;
  organization = '';
  projects: any[] = [];
  selectedProject: string = '';
  userMenuOpen = false;
  isPreviewRoute = false;
  isPublicRoute = false;
  isAdmin = false;
  version = 'v3.0.0';

  constructor(
    private store: Store<AppState>,

    private platform: Platform,
    public dialog: MatDialog,
    private appUser: AppUserService,
    private appState: AppStateService,
    private cdr: ChangeDetectorRef,
    private subMgr: SubscriptionHelper,
    private ngxCookieService: CookieService,
    private uamAuthService: UAMAuthService,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        const url = event.urlAfterRedirects;
        this.isAdminRoute = url.startsWith('/admin');
        this.isPublicRoute = url.startsWith('/public');
        this.isAuthRoute = url.startsWith('/auth');
        this.isPreviewRoute = url.includes('previewId=');
        this.isRegistrationRoute = url.startsWith('/registration');
      });
  }

  ngOnDestroy() {
    this.subMgr.destroy();
  }

  ngOnInit(): void {
    this.version = `v${environment.version}`;
    if (this.isAdminRoute) {
      this.uamAuthService.checkAuthentication().subscribe();

      this.subMgr.add(
        this.store
          .pipe(
            select(selectCurrentUser),
            filter(currentUser => !!currentUser && currentUser.sub !== '')
          )
          .subscribe((currentUser: AppUserState) => {
            this.currentUser = currentUser;
            if (
              this.currentUser.role === 'pavcon' ||
              this.currentUser.role === 'organization-admin' ||
              this.currentUser.role === 'organization-user'
            ) {
              this.isAdmin = true;
            }
            if (this.isAdmin) {
              this.loadProjects();
            }
          })
      );

      this.subMgr.add(
        this.uamAuthService.loggedIn.subscribe(isLoggedIn => {
          if (!isLoggedIn && this.isAdminRoute) {
            this.logout();
          }
        })
      );
    }
  }

  loadProjects() {
    if (this.projects.length) return;

    this.appUser
      .getOrganizationProjects()
      .then((projects: any[]) => {
        if (projects) {
          this.projects = projects.map((p) => p.project);
          if (this.projects.length > 0) {
            if (!this.selectedProject.length) {
              this.selectedProject = this.projects[0] as string;
              this.appState.updateSelectedProject(this.selectedProject);
            }
            this.cdr.detectChanges();
          }
        }
      })
      .catch((error) => {
        console.log('error on load projects', error);
      });
  }

  onProjectChanged(project: any) {
    this.selectedProject = project;
    if (this.selectedProject) {
      this.appState.updateSelectedProject(this.selectedProject);
    }
  }

  goHome() {
    if (this.isAdminRoute) {
      this.router.navigateByUrl('/admin');
    } else {
      this.router.navigateByUrl('/');
    }
  }
  get safeAreaHeight() {
    if (!this.platform.isBrowser) {
      return 0;
    }
    alert('is not browser');
    const height = window.innerHeight * window.devicePixelRatio;
    const safeAreaHeight = height - 44;
    return safeAreaHeight;
  }


  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.ngxCookieService.delete(
      'uam-id-token',
      '/',
      environment.cookieInfo.domain
    );
    this.ngxCookieService.delete(
      'uam-refresh-token',
      '/',
      environment.cookieInfo.domain
    );
    this.ngxCookieService.delete(
      'uam-device-key',
      '/',
      environment.cookieInfo.domain
    );
    document.location.href = environment.uamUrl + 'auth/logout';
  }

  selectProject(projectName: string) {
    this.selectedProject = projectName;
  }
  applyProjectSelection() {
    this.appUser.setSelectedProject(this.selectedProject);
  }
  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }

  getUserInitials(): string {
    if (!this.currentUser?.name) return '';

    const firstName = this.currentUser.name.charAt(0);
    const lastName = this.currentUser.family_name
      ? this.currentUser.family_name.charAt(0)
      : '';

    return (firstName + lastName).toUpperCase();
  }
}

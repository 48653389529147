<div class="modal-header flex items-center space-x-4 px-5 pt-5 bg-white">
  <div class="text-lg font-normal mb-2 border-b-gray-100">Settings: {{ tableField.name }}</div>
</div>

<div class="modal-body px-4 pt-2 pb-4 bg-white">

  <div class="flex flex-col gap-3">

    <!--
    TO ADD LATER
    <div class="mb-4 flex items-center justify-start items-center bg-gray-100 rounded-md p-4" *ngIf="index===0">
      <input type="checkbox" [(ngModel)]="tableField.sticky"
        class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
      <label class="ml-2 block text-sm font-medium text-gray-700 mr-2">Use Sticky header</label>

    </div> -->

    <div class="flex flex-col gap-1">
      <label class="text-sm text-gray-600">Field Mode</label>
      <select [(ngModel)]="tableField.editable"
        (ngModelChange)="tableField.editable = $event === 'true' ? true : false;"
        class="border border-gray-300 rounded p-2">
        <option [value]="true">Editable</option>
        <option [value]="false">Read-only</option>
      </select>
    </div>

    <div class="flex flex-col gap-1">
      <label class="text-sm text-gray-600">Column Size</label>
      <input type="text" [(ngModel)]="tableField.size"
        class="flex-grow bg-transparent border border-gray-300 p-1 rounded">
      <small>Enter the table size in pixels as an integer, e.g., ‘100’.</small>
    </div>
  </div>




</div>


<div class="modal-footer flex justify-end space-x-3 p-5 bg-white ">
  <button class="bg-blue-500 text-white rounded hover:bg-blue-600 px-5 py-2 focus:outline-none" (click)="close()">
    Close
  </button>
</div>
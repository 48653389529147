<div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
  <div class="bg-white rounded-lg p-6 w-11/12 max-w-md">
    <h2 class="text-xl font-bold mb-2">{{ data.title }}</h2>

    <p class="mb-1 text-gray-800">{{ data.message }}</p>
    <p class="mb-3 text-gray-700 text-sm">This could take upwards of 10 minutes.</p>
    <div class="relative">
      <div class="w-full bg-gray-200 rounded-full h-4 overflow-hidden">
        <div class="bg-blue-600 h-full transition-all duration-500 ease-in-out" [style.width.%]="data.progress"></div>
      </div>
      <div class="absolute top-0 right-0 -mt-5 mr-2 text-sm text-gray-700">
        {{ progressFraction }}
      </div>

    </div>

    <div class="mt-2 text-md text-red-500 font-semibold">Please do not close this page.</div>
  </div>
</div>

<div class="pdf-page shadow-lg relative">

  <div *ngIf="useWatermark" class="watermark">
    {{ watermarkText }}
  </div>


  <header *ngIf="showHeader" class="pdf-header">
    <ng-content select="[header]"></ng-content>
  </header>
  <main class="pdf-content">
    <ng-content></ng-content>
  </main>
  <footer *ngIf="showFooter" class="pdf-footer">
    <ng-content select="[footer]"></ng-content>
  </footer>
</div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class customCookieService {
  constructor() { }

  set(
    name: string,
    value: string = '',
    options?: {
      expires?: number;
      domain?: string;
      path?: string;
      secure?: boolean;
      sameSite?: 'Lax' | 'Strict' | 'None';
    }
  ) {
    let cookieString = `${name}=${encodeURIComponent(value)}`;

    if (options) {
      if (options.expires) {
        const date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
        cookieString += `; expires=${date.toUTCString()}`;
      }
      if (options.domain) {
        cookieString += `; domain=${options.domain}`;
      }
      if (options.path) {
        cookieString += `; path=${options.path}`;
      }
      if (options.secure) {
        cookieString += `; secure`;
      }
      if (options.sameSite) {
        cookieString += `; SameSite=${options.sameSite}`;
      }
    } else {
      cookieString += '; path=/';
    }

    document.cookie = cookieString;
  }

  get(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

}

<aside [ngClass]="{ 'w-20': isSlimMode, 'w-[250px]': !isSlimMode }" class="block bg-black dark:bg-dark-sidebar no-print"
  style="height: 100vh">
  <div class="flex-1 overflow-auto p-4 flex flex-col justify-between h-full">

    <nav class="flex flex-col text-lg w-full h-full justify-start text-center my-6">

      @for (nav of navigation; track $index) {
      <div class="space-y-4 w-full text-center items-center mb-2" *ngIf="haveAccess(nav)">
        <a *ngIf="nav.routes" class="sidebar-item mb-2" [ngClass]="{
                              active: isActive(nav.routes),
                              'flex items-center space-x-2': !isSlimMode,
                              icon: isSlimMode
                            }" (click)="onNavigationClick(nav)">
          <lucide-icon [name]="nav.icon" class="text-white"></lucide-icon>
          <div *ngIf="!isSlimMode" class="sidebar-text">
            {{ nav.name }}
          </div>
        </a>
      </div>
      }
    </nav>
  </div>
</aside>
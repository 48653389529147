import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss'],
})
export class ProgressModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ProgressModalData) { }

  get progressFraction(): string {
    if (this.data.totalPages && this.data.totalPages > 0) {
      return `${this.data.currentPage}/${this.data.totalPages}`;
    } else {
      return `${this.data.progress.toFixed(0)}%`;
    }
  }
}

export interface ProgressModalData {
  title: string;
  subtitle: string;
  message: string;
  progress: number;
  currentPage: number;
  totalPages: number;
}

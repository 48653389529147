<!-- Modal Header con Ícono -->
<div class="modal-header flex items-center space-x-4 px-5 pt-5 bg-white dark:bg-gray-700">

</div>

<div class="modal-body px-5 pt-2 pb-4 bg-white dark:bg-gray-700">
  <div class="justify-start gap-2 flex flex-row items-start">
    <div>
      @if(data.type === 'error'){
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6 text-red-500">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
      </svg>
      } @else if (data.type === 'delete') {
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-[50px] h-[50px] text-red-500">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
      </svg>
      } @else if (data.type === 'confirmation') {
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-[50px] h-[50px] text-blue-500">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
      </svg>

      }
    </div>
    <div>
      <span class="text-gray-700 text-xl font-semibold dark:text-gray-200">
        {{ data.title || 'Are you sure?' }}
      </span>
      <p class="text-gray-700 dark:text-gray-100" [innerHTML]="data?.innerHTML?.message || data.message "></p>
    </div>
  </div>


  <div class="modal-footer flex justify-end space-x-3 p-5 bg-white dark:bg-gray-700">
    <button class="bg-gray-200 text-gray-800 rounded hover:bg-gray-300 px-5 py-2 focus:outline-none"
      (click)="onCancel()" *ngIf="data.showCancelButton ?? true">
      {{ cancelButtonText }}
    </button>
    <button class="bg-blue-500 text-white rounded hover:bg-blue-600 px-5 py-2 focus:outline-none" (click)="onConfirm()">
      {{ confirmButtonText }}
    </button>
  </div>
  <!-- || 'Do you want to proceed with this action?' -->

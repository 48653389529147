<div [ngClass]="{'pointer-events-none opacity-50': disabled}" appScrollbar>
  <table [style.min-width.px]="adjustedWidth"
    class="min-w-full bg-gray-50 text-gray-700 uppercase text-xs font-bold border-l border-b border-t border-gray-300">
    <thead>
      <tr class="bg-gray-100">
        <ng-container *ngFor="let field of table.fields">
          <th class="text-center py-1 border-r border-gray-300" [ngStyle]="{'width': (field?.size || 100) + 'px'}">
            {{ field.name }}
          </th>
        </ng-container>
        <th *ngIf="field?.metadata?.allowAddRows && table.rows.length > defaultRowTotals"
          class="text-center py-1 border-r border-gray-300" style="width: 20px;">
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of table.rows; trackBy: trackByFn; let i = index">
        <ng-container *ngFor="let field of table.fields; trackBy: trackByFn">
          <td [ngClass]="{'bg-gray-100': !field.editable, 'bg-white': field.editable}"
            [ngStyle]="{'width': (field?.size || 100) + 'px'}" class="border border-gray-300">
            <div *ngIf="field.editable" class="py-1 px-2 relative">
              <input [(ngModel)]="row[field.uuid]" (ngModelChange)="emitDataChange()"
                class="w-full bg-transparent border-none focus:outline-none" [disabled]="disabled"
                placeholder="Enter value">
              <button *ngIf="field.editable && expandable"
                class="absolute inset-y-0 right-0 px-2 flex items-center h-full"
                (click)="openModal(field.uuid, row, field.name, i)">
                <lucide-icon name="maximize-2" class="w-4 h-4"></lucide-icon>
              </button>
            </div>
            <span *ngIf="!field.editable" class="py-1 px-2">{{ row[field.uuid] }}</span>
          </td>
        </ng-container>

        <td *ngIf="field?.metadata?.allowAddRows && table.rows.length > defaultRowTotals"
          class="border border-gray-300 text-center p-0" style="width: 20px;">
          <button *ngIf="i + 1 > defaultRowTotals" (click)="deleteRow(i)" class="text-red-500 p-0 m-0">
            <lucide-icon name="trash-2" class="w-4 h-4"></lucide-icon>
          </button>
        </td>

      </tr>
    </tbody>
  </table>
</div>
<div class="mt-4 flex justify-start" *ngIf="field?.metadata?.allowAddRows || false">
  <button (click)="addRow()" class="primary-button flex flex-row text-xs" [disabled]="disabled">
    <lucide-angular name="plus" class="h-4 w-4"></lucide-angular>
    <span>Add Row</span>
  </button>
</div>
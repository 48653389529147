import { NgModule } from '@angular/core';
import {
  LucideAngularModule,
  Home,
  User,
  Bell,
  FolderCog,
  ChevronDown,
  Edit,
  FileText,
  HelpCircle,
  Info,
  LogOut,
  Menu,
  Plus,
  Search,
  Settings,
  Star,
  Hammer,
  Wrench,
  Pencil,
  Trash2,
  Fullscreen,
  CircleX,
  CircleGauge,
  Gauge,
  LayoutDashboard,
  Bug,
  Flag,
  Activity,
  HandCoins,
  CircleDollarSign,
  Landmark,
  ListPlus,
  FileClock,
  PanelsTopLeft,
  Library,
  BookText,
  Eye,
  Loader,
  Save,
  Replace,
  X,
  RectangleEllipsis,
  BookDashed,
  Inbox,
  ArrowLeftToLine,
  ArrowRightToLine,
  Filter,
  Contact,
  Store,
  CalendarDays,
  Calendar,
  AreaChart,
  TextCursorInput,
  ClipboardList,
  Timer,
  Check,
  OctagonX,
  ImageUp,
  Delete,
  Grip,
  EllipsisVertical,
  Copy,
  PencilLine,
  SquareX,
  ArrowDown,
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  Group,
  BetweenVerticalStart,
  ListX,
  Zap,
  Columns3,
  OctagonPause,
  AtSign,
  SearchX,
  Phone,
  Link,
  BoxSelect,
  ArrowRight,
  ShieldAlert,
  BookA,
  ArrowUpFromLine,
  Upload,
  ThumbsUp,
  SquarePercent,
  Shapes,
  Maximize2,
  ClipboardType,
  CloudUpload,
  Download,
  CheckCheck,
  Printer,
  MonitorCheck,
  File,
  FileX,
  Clock,
  LayoutGrid,
  Lock,
  BarChart2,
  Users,
  Share2,
  UserRoundX,
  MessageCircle,
  Send,
  ChevronsRight,
  ChevronsLeft,
  PanelLeft,
  PanelRight,
  PanelLeftClose,
  SeparatorHorizontal,
  LayoutPanelLeft,
  ChevronUp,
  ChevronsUp,
  ChevronsDown,
  FileCheck,
  Unlock,
  FileEdit,
  MoreVertical,
  Scroll,
  Split,
  LayoutList,
  WandSparkles,
  GitBranch,
  ListOrdered,
  PlusCircle,
  Play,
  CheckCircle2,
  CheckCircle,
  FilePlus,
  XCircle,
  Key,
  ArrowLeft,
  KeyRound,
  CircleDot,
  Hash,
  ArrowUpDown,
  Sparkles,
  FileQuestion,
  FileDown,
} from 'lucide-angular';

@NgModule({
  imports: [
    LucideAngularModule.pick({
      LayoutGrid,
      Users,
      Play,
      FileDown,
      Key,
      FileQuestion,
      Sparkles,
      ArrowUpDown,
      ArrowLeft,
      Hash,
      KeyRound,
      CheckCircle2,
      CheckCircle,
      ChevronUp,
      FilePlus,
      CircleDot,
      LayoutPanelLeft,
      FileCheck,
      Scroll,
      XCircle,
      Split,
      ListOrdered,
      PlusCircle,
      FileEdit,
      LayoutList,
      WandSparkles,
      GitBranch,
      Unlock,
      ChevronsUp,
      ChevronsDown,
      SeparatorHorizontal,
      PanelLeftClose,
      PanelLeft,
      PanelRight,
      Send,
      Share2,
      MessageCircle,
      UserRoundX,
      BarChart2,
      Lock,
      Clock,
      Home,
      User,
      Settings,
      Bell,
      FileText,
      LogOut,
      Menu,
      Hammer,
      ChevronDown,
      FolderCog,
      Wrench,
      Info,
      Star,
      Search,
      Plus,
      Edit,
      HelpCircle,
      Pencil,
      Trash2,
      Fullscreen,
      CircleX,
      CircleGauge,
      Gauge,
      LayoutDashboard,
      Bug,
      Flag,
      Activity,
      HandCoins,
      CircleDollarSign,
      Landmark,
      ListPlus,
      FileClock,
      PanelsTopLeft,
      Library,
      BookText,
      Eye,
      Loader,
      Save,
      Replace,
      RectangleEllipsis,
      X,
      BookDashed,
      Inbox,
      ArrowLeftToLine,
      ArrowRightToLine,
      Filter,
      Contact,
      Store,
      CalendarDays,
      Calendar,
      AreaChart,
      TextCursorInput,
      ClipboardList,
      Timer,
      Check,
      OctagonX,
      ImageUp,
      Grip,
      Delete,
      EllipsisVertical,
      Copy,
      SquareX,
      PencilLine,
      ArrowUp,
      ArrowDown,
      ChevronLeft,
      ChevronRight,
      Group,
      ListX,
      BetweenVerticalStart,
      Zap,
      Columns3,
      OctagonPause,
      AtSign,
      Phone,
      Link,
      SearchX,
      BoxSelect,
      ArrowRight,
      ShieldAlert,
      BookA,
      ThumbsUp,
      Upload,
      Shapes,
      SquarePercent,
      ArrowUpFromLine,
      Maximize2,
      ClipboardType,
      CloudUpload,
      Download,
      CheckCheck,
      Printer,
      MonitorCheck,
      File,
      FileX,
      ChevronsRight,
      ChevronsLeft,
      MoreVertical,
    }),
  ],
  exports: [LucideAngularModule],
  declarations: [],
  providers: [],
})
export class LucideIconsModule { }

import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appScrollbar]',
})
export class ScrollbarDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    this.renderer.setStyle(this.el.nativeElement, 'overflow-x', 'auto');

    const styles = `
      ::-webkit-scrollbar {
        height: 12px;
        width: 12px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        border: 3px solid #f1f1f1;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    this.appendStyles(styles);
  }

  private appendStyles(styles: string) {
    const styleSheet = document.createElement('style');
    styleSheet.innerText = styles;
    this.el.nativeElement.appendChild(styleSheet);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-page',
  templateUrl: './pdf-page.component.html',
  styleUrl: './pdf-page.component.scss'
})
export class PdfPageComponent {
  @Input() showHeader: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() useWatermark: boolean = false;
  @Input() watermarkText: string = '';
}

<aside class="transition-all duration-300" [class]="isCollapsed ? 'w-[64px]' : 'w-[250px]'"
  class="block bg-black dark:bg-dark-sidebar no-print shadow-sidebar" style="height: 100vh">
  <div class="flex-1 overflow-hidden p-4 flex flex-col justify-between h-full">
    <button class="toggle-button flex items-center justify-center cursor-pointer" (click)="toggleSidebar()">
      <lucide-icon [name]="isCollapsed ? 'chevron-right' : 'chevron-left'" class="text-white w-4 h-4">
      </lucide-icon>
    </button>

    <nav class="flex flex-col w-full h-full justify-start my-6">
      @if (haveAccess(navigation[0])) {
      <div class="w-full mb-2">
        <a class="sidebar-item flex items-center" (click)="onNavigationClick(navigation[0])"
          [class.justify-center]="isCollapsed" [matTooltip]="isCollapsed ? 'Home' : ''" matTooltipPosition="right"
          [matTooltipClass]="'sidebar-tooltip mat-tooltip-panel-showing bg-[#2A2A2A]'" [matTooltipShowDelay]="0"
          [matTooltipHideDelay]="0">
          <lucide-icon name="home" class="text-white w-5 h-5"></lucide-icon>
          @if (!isCollapsed) {
          <span class="text-white text-sm font-medium ml-3">Home</span>
          }
        </a>
      </div>
      }

      <div class="my-6 border-b border-white/20"></div>

      @for (category of categories; track category) {
      @if (hasCategoryItems(category)) {
      <div class="mb-8">
        @if (!isCollapsed) {
        <div class="mb-3 px-3">
          <span class="text-xs font-semibold text-white/80 uppercase tracking-wider">
            {{ getCategoryTitle(category) }}
          </span>
          <div class="mt-2 bg-white/10" style="height: 1px;"></div>
        </div>
        }

        @for (nav of getNavigationByCategory(category); track nav.name) {
        @if (haveAccess(nav)) {
        <div class="w-full mb-2">
          <a class="sidebar-item flex items-center" [class.justify-center]="isCollapsed"
            [class.active]="isActive(nav.routes)" (click)="onNavigationClick(nav)"
            [matTooltip]="isCollapsed ? nav.name : ''" matTooltipPosition="right"
            [matTooltipClass]="'sidebar-tooltip mat-tooltip-panel-showing bg-[#2A2A2A]'" [matTooltipShowDelay]="0"
            [matTooltipHideDelay]="0">
            <lucide-icon [name]="nav.icon" class="text-white w-5 h-5"></lucide-icon>
            @if (!isCollapsed) {
            <span class="text-white text-sm font-medium ml-3 whitespace-nowrap">{{ nav.name }}</span>
            }
          </a>
        </div>
        }
        }
      </div>
      }
      }
    </nav>
  </div>
</aside>
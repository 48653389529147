import { CaseConverter } from 'src/app/shared/helpers/case-converter.helper';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormField,
  FormTableStructure,
  TableField,
} from '../../entities/form.entity';
import { UUIDHelper } from '../../helpers/uuid.helper';
import { MatDialog } from '@angular/material/dialog';
import { ExpandibleModalComponent } from '../expandible-modal/expandible-modal.component';

@Component({
  selector: 'app-field-table',
  templateUrl: './field-table.component.html',
  styleUrl: './field-table.component.scss',
})
export class FieldTableComponent {
  @Output() onChanges = new EventEmitter<FormTableStructure>();
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: any;
  }>();
  selectedTab: string = 'columns';
  @Input() fieldId!: string;
  @Input() field!: FormField;
  @Input() table!: FormTableStructure;
  @Input() data!: any;
  @Input() disabled: boolean = false;
  @Input() expandable: boolean = false;
  expectedWidth!: number;
  defaultRowTotals = 0;
  columnTotals: { [key: string]: number; } = {};

  constructor(
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['table'] && changes['table'].currentValue) {
      this.table = changes['table'].currentValue;
      this.updateRows();
      this.calculateExpectedWidth();
      this.calculateColumnTotals();
    }

    if (changes['data'] && changes['data'].currentValue) {
      this.table = {
        ...this.table,
        rows: CaseConverter.keysToCamelCase(changes['data'].currentValue),
      };

      this.table = this.cleanUUIDs(this.table);
      this.calculateColumnTotals();
      this.cdr.detectChanges();
    }
    if (changes['field'] && changes['field'].currentValue) {
      this.defaultRowTotals = this.field.table?.rows.length || 0;
    }
  }
  deleteRow(index: number): void {
    this.table.rows.splice(index, 1);
    this.emitDataChange();
  }
  cleanUUIDs(data: any): any {
    const removeDashes = (str: string): string =>
      str.replace(/-/g, '').toUpperCase();
    const cleanedFields = data.fields.map((field: any) => ({
      ...field,
      uuid: removeDashes(field.uuid),
    }));

    const cleanedRows = data.rows.map((row: any) => {
      const cleanedRow: any = {};
      Object.keys(row).forEach((key) => {
        cleanedRow[removeDashes(key)] = row[key];
      });
      return cleanedRow;
    });

    const table = {
      fields: cleanedFields,
      rows: cleanedRows,
    };
    return table;
  }
  calculateExpectedWidth() {
    this.expectedWidth = this.table.fields.reduce((acc, field) => {
      const size = field.size ? +field.size : 100;
      return acc + size + 80;
    }, 0);
  }

  selectTab(tabName: string): void {
    this.selectedTab = tabName;
  }

  trackByFn(index: number, item: any): number {
    return item ? item.id : index;
  }

  emitChanges() {
    this.updateRows();
    this.onChanges.emit(this.table);
    if (this.fieldId) {
      const isValid = this.checkIfAnyValueExists(this.table.rows);
      this.onFieldChange.emit({
        fieldId: this.fieldId,
        isValid,
        value: this.table.rows,
      });
    }
    this.cdr.detectChanges();
  }

  checkIfAnyValueExists(items: Array<{ [key: string]: any; }>): boolean {
    for (const item of items) {
      for (const key in item) {
        if (item[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }
  get adjustedWidth(): number {
    return this.field?.metadata?.allowAddRows &&
      this.table.rows.length > this.defaultRowTotals
      ? this.expectedWidth - 20
      : this.expectedWidth;
  }

  emitDataChange(): void {
    this.updateRows();
    this.calculateColumnTotals();
    this.emitChanges();
  }

  addColumn(): void {
    const columnName = 'column-name';
    this.table.fields.push({
      uuid: UUIDHelper.generateShortUUID(),
      name: columnName,
      editable: true,
      type: 'column',
      sub_fields: [],
    });
    this.updateRows();
    this.emitChanges();
  }

  changeColumnType(index: number, type: 'column' | 'group'): void {
    const field = this.table.fields[index];
    if (type === 'group') {
      field.type = 'group';
      field.sub_fields = [
        {
          uuid: UUIDHelper.generateShortUUID(),
          name: 'sub-column-name',
          editable: true,
          type: 'column',
          sub_fields: [],
        },
      ];
      this.updateRowsForGroup(field.uuid, field.sub_fields);
    } else if (type === 'column') {
      field.type = 'column';
      field.sub_fields = [];
    }
    this.updateRows();
    this.emitChanges();
  }

  updateRowsForGroup(groupKey: string, subFields: TableField[]): void {
    this.table.rows = this.table.rows.map((row) => {
      const newRow = { ...row };
      if (typeof newRow[groupKey] === 'string') {
        newRow[groupKey] = {};
      }
      subFields.forEach((subField) => {
        if (!newRow[groupKey][subField.uuid]) {
          newRow[groupKey][subField.uuid] = '';
        }
      });
      return newRow;
    });
    this.updateRows();
    this.emitChanges();
  }

  addSubColumn(parentIndex: number): void {
    const parent = this.table.fields[parentIndex];
    if (!parent.sub_fields) {
      parent.sub_fields = [];
    }
    parent.sub_fields.push({
      uuid: UUIDHelper.generateShortUUID(),
      name: '',
      editable: true,
      type: 'column',
      sub_fields: [],
    });
    this.updateRowsForGroup(parent.uuid, parent.sub_fields);
    this.updateRows();
    this.emitChanges();
  }

  removeColumn(index: number): void {
    this.table.fields.splice(index, 1);
    this.updateRows();
    this.emitChanges();
  }

  removeSubColumn(parentIndex: number, subIndex: number): void {
    if (
      this.table.fields &&
      this.table.fields[parentIndex] &&
      this.table.fields[parentIndex].sub_fields &&
      this.table.fields[parentIndex].sub_fields.length > subIndex
    ) {
      this.table.fields[parentIndex].sub_fields.splice(subIndex, 1);
      this.updateRows();
      this.emitChanges();
    }
  }

  getTotalColumns(): number {
    let total = 0;
    this.table.fields.forEach((field) => {
      if (field.type === 'group') {
        total += field.sub_fields.length;
      } else {
        total++;
      }
    });
    return total;
  }

  getColumnClass(field: TableField): string {
    if (field.type === 'group') {
      return `col-span-${field.sub_fields.length} flex flex-col items-center`;
    } else {
      return 'flex-1';
    }
  }

  updateRows(): void {
    this.table.rows = this.table.rows.map((row) => {
      const newRow: { [key: string]: any; } = {};
      this.table.fields.forEach((field) => {
        newRow[field.uuid] = field.type === 'group' ? {} : '';

        if (field.type === 'group' && field.sub_fields) {
          field.sub_fields.forEach((subField) => {
            newRow[field.uuid][subField.uuid] =
              row[field.uuid] && row[field.uuid][subField.uuid] !== undefined
                ? row[field.uuid][subField.uuid]
                : '';
          });
        } else {
          newRow[field.uuid] =
            row[field.uuid] !== undefined ? row[field.uuid] : '';
        }
      });
      return newRow;
    });
  }

  addRow(): void {
    const newRow: { [key: string]: any; } = {};
    this.table.fields.forEach((field) => {
      if (field.type === 'group' && field.sub_fields) {
        newRow[field.uuid] = {};
        field.sub_fields.forEach((subField) => {
          newRow[field.uuid][subField.uuid] = '';
        });
      } else {
        newRow[field.uuid] = '';
      }
    });
    this.table.rows.push(newRow);
    this.emitChanges();
  }
  openModal(
    fieldUUID: string,
    row: any,
    columnName: string,
    rowIndex: number
  ): void {
    const currentValue = row[fieldUUID];
    const dialogRef = this.dialog.open(ExpandibleModalComponent, {
      width: '500px',
      data: { columnName, text: currentValue, rowIndex: rowIndex + 1 },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined && result.action === 'update' && result.value) {
        row[fieldUUID] = result.value;
        this.emitDataChange();
      }
    });
  }

  calculateColumnTotals(): void {
    this.columnTotals = {};

    this.table.fields.forEach((field) => {
      if (field.data_type === 'numeric' && field.enable_totals) {
        this.columnTotals[field.uuid] = 0;

        this.table.rows.forEach((row) => {
          const value = parseFloat(row[field.uuid]) || 0;
          this.columnTotals[field.uuid] += value;
        });
      }
    });
  }

  shouldShowTotalsRow(): boolean {
    return this.table.fields.some(
      (field) => field.data_type === 'numeric' && field.enable_totals
    );
  }

  onNumericInput(event: any, row: any, field: TableField): boolean {
    // Allow backspace and delete for normal text deletion
    if (event.key === 'Backspace' || event.key === 'Delete') {
      return true;
    }

    // Allow copy/paste and selection shortcuts
    if (event.ctrlKey || event.metaKey) {  // metaKey for Mac support
      // Allow: Ctrl/Cmd + A, C, V, X
      if (['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) {
        return true;
      }
    }

    // Allow navigation keys (arrows, home, end)
    if (
      [
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Home',
        'End',
        'Tab',
      ].includes(event.key)
    ) {
      return true;
    }

    // Allow decimal point
    if (event.key === '.') {
      if (event.target.value.includes('.')) {
        event.preventDefault();
        return false;
      }
      return true;
    }

    // Allow minus sign only at start
    if (event.key === '-') {
      if (
        event.target.selectionStart !== 0 ||
        event.target.value.includes('-')
      ) {
        event.preventDefault();
        return false;
      }
      return true;
    }

    // Only allow numbers
    if (isNaN(Number(event.key))) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  onNumericBlur(event: any, row: any, field: TableField): void {
    const value = event.target.value;
    if (value === '' || value === '-') return;

    // Remove commas before parsing
    const cleanValue = value.replace(/,/g, '');

    // Use parseFloat but maintain the original precision
    if (!isNaN(Number(cleanValue))) {
      // Store the raw number value without formatting
      row[field.uuid] = cleanValue;
      // Display formatted value
      event.target.value = this.formatNumber(cleanValue);
      this.emitDataChange();
    }
  }

  // Modify the format number method to handle large numbers
  formatNumber(value: any): string {
    if (value === '' || value === null || value === undefined) return '';

    // Remove any existing commas first
    const cleanValue = value.toString().replace(/,/g, '');

    if (isNaN(Number(cleanValue))) return value;

    // Split number into integer and decimal parts
    const [integerPart, decimalPart] = cleanValue.split('.');

    // Format integer part with commas
    const formattedInteger = parseInt(integerPart).toLocaleString('en-US');

    // Return formatted number with original decimal places
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  }

  // Add new method to handle paste events
  onPaste(event: ClipboardEvent, row: any, field: TableField): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';

    // Clean the pasted text to only allow numbers, decimal point and minus sign
    const cleanedText = pastedText.replace(/[^\d.-]/g, '');

    if (!isNaN(Number(cleanedText))) {
      const input = event.target as HTMLInputElement;
      const start = input.selectionStart || 0;
      const end = input.selectionEnd || 0;

      const currentValue = input.value;
      const newValue = currentValue.substring(0, start) + cleanedText + currentValue.substring(end);

      if (!isNaN(Number(newValue))) {
        row[field.uuid] = newValue;
        input.value = newValue;
        this.emitDataChange();
      }
    }
  }

  /**
   * Determines if a column should be sticky based on its position and editability
   */
  shouldBeSticky(colIndex: number): boolean {
    let stickyColumns = 0;

    // Check consecutive readonly columns from the start
    for (let i = 0; i < this.table.fields.length && i <= colIndex; i++) {
      if (!this.table.fields[i].editable) {
        stickyColumns++;
      } else {
        break;
      }
    }

    return colIndex < stickyColumns;
  }

  /**
   * Calculates the left position for sticky columns
   */
  getStickyPosition(colIndex: number): string {
    if (!this.shouldBeSticky(colIndex)) {
      return 'auto';
    }

    let position = 0;
    for (let i = 0; i < colIndex; i++) {
      const fieldSize = this.table.fields[i]?.size;
      position += (fieldSize ? parseInt(fieldSize.toString()) : 100);
    }

    return `${position}px`;
  }

  /**
   * Gets the background color for sticky cells
   */
  getStickyBackground(isHeader: boolean = false): string {
    return isHeader ? 'rgb(243 244 246)' : 'rgb(229 231 235)';
  }

  /**
   * Gets the maximum length for a field
   */
  getMaxLength(field: TableField): number {
    if (field.data_type === 'alphanumeric') {
      return field.max_characters || 50;
    }
    return 524288; // Default HTML input maxlength
  }

  /**
   * Handles input changes for alphanumeric fields
   */
  onInputChange(event: Event, row: any, field: TableField): void {
    if (field.data_type === 'alphanumeric') {
      const input = event.target as HTMLInputElement;
      const maxLength = this.getMaxLength(field);

      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength);
        row[field.uuid] = input.value;
        this.emitDataChange();
      }
    }
  }

  /**
   * Handles paste events for alphanumeric fields
   */
  onAlphanumericPaste(event: ClipboardEvent, row: any, field: TableField): void {
    if (field.data_type === 'alphanumeric') {
      event.preventDefault();
      const pastedText = event.clipboardData?.getData('text') || '';
      const maxLength = this.getMaxLength(field);

      const input = event.target as HTMLInputElement;
      const start = input.selectionStart || 0;
      const end = input.selectionEnd || 0;

      const currentValue = input.value;
      let newValue = currentValue.substring(0, start) + pastedText + currentValue.substring(end);

      // Truncate if exceeds max length
      if (newValue.length > maxLength) {
        newValue = newValue.slice(0, maxLength);
      }

      row[field.uuid] = newValue;
      input.value = newValue;
      this.emitDataChange();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UAMAuthService } from 'src/app/shared/services/uam-auth.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { SidebarStateService } from 'src/app/shared/services/sidebar-state.service';

type NavigationCategory = 'builders' | 'management' | 'lookup' | 'applications';

interface NavigationItem {
  name: string;
  icon: string;
  routes: string[];
  roles: string[];
  category?: NavigationCategory;
  isDisabled?: boolean;
  sidebarState?: 'open' | 'closed';
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  currentUser: any = {};
  @Input() isSlimMode = false;
  @Input() set forceSidebarState(state: 'open' | 'closed' | null) {
    if (state) {
      this.isCollapsed = state === 'closed';
    }
  }

  readonly categories: NavigationCategory[] = [
    'builders',
    'management',
    'lookup',
    'applications',
  ];

  navigation: NavigationItem[] = [
    {
      name: 'Home',
      icon: 'home',
      routes: ['/admin'],
      roles: [
        'pavcon',
        'organization-admin',
        'organization-user',
        'base',
        'pavcon-user',
      ],
      sidebarState: 'open',
    },
    // Builder Tools
    {
      name: 'Surveys Builder',
      icon: 'clipboard-list',
      routes: ['/admin/surveys', '/admin/survey'],
      roles: ['pavcon', 'organization-admin'],
      category: 'builders',
    },
    {
      name: 'Forms Builder',
      icon: 'hammer',
      routes: ['/admin/form-builder', '/admin/form-editor'],
      roles: ['pavcon'],
      category: 'builders',
      sidebarState: 'closed',
    },
    // Lookup Tools
    {
      name: 'Artifacts Lookup',
      icon: 'search',
      routes: ['/admin/search-artifacts'],
      roles: ['pavcon', 'pavcon-user'],
      category: 'lookup',
    },
    {
      name: 'Forms Data Lookup',
      icon: 'clipboard-type',
      routes: ['/admin/forms-data-lookup'],
      roles: ['pavcon', 'pavcon-user'],
      category: 'lookup',
    },
    // Management Tools
    {
      name: 'Sessions',
      icon: 'calendar',
      routes: ['/admin/sessions'],
      roles: ['pavcon', 'organization-admin', 'organization-user'],
      category: 'management',
    },
    {
      name: 'Insights Hub',
      icon: 'bar-chart-2',
      routes: ['/admin/results'],
      roles: ['pavcon', 'organization-admin', 'organization-user'],
      category: 'management',
    },
    // Applications
    {
      name: 'Forms',
      icon: 'file-text',
      routes: ['/admin/assignments', '/admin/assignments/workarea'],
      roles: ['pavcon', 'base'],
      category: 'applications',
    },
  ];

  isCollapsed = false;

  constructor(
    private router: Router,
    private uamService: UAMAuthService,
    private sidebarStateService: SidebarStateService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkRouteConfiguration();
      });

    this.sidebarStateService.sidebarState$.subscribe(state => {
      if (state) {
        this.isCollapsed = state === 'closed';
      }
    });
  }

  ngOnInit() {
    this.currentUser = this.uamService.getAuthenticatedUser();
  }

  private checkRouteConfiguration() {
    const currentUrl = this.router.url;
    const matchingNav = this.navigation.find((nav) =>
      nav.routes.some((route) => currentUrl.includes(route))
    );

    if (matchingNav?.sidebarState) {
      this.isCollapsed = matchingNav.sidebarState === 'closed';
    }
  }

  haveAccess(nav: NavigationItem): boolean {
    const userRole =
      this.currentUser?.['custom:warfighter_role']?.toLowerCase();
    return nav.roles?.some((role) => role.toLowerCase() === userRole);
  }

  getNavigationByCategory(category?: NavigationCategory): NavigationItem[] {
    return this.navigation.filter((item) => item.category === category);
  }

  getCategoryTitle(category: NavigationCategory): string {
    const titles: Record<NavigationCategory, string> = {
      builders: 'Builder Tools',
      management: 'Management',
      lookup: 'Lookup Tools',
      applications: 'Applications',
    };
    return titles[category];
  }

  hasCategoryItems(category: NavigationCategory): boolean {
    return this.getNavigationByCategory(category).some((nav) =>
      this.haveAccess(nav)
    );
  }

  isActive(paths: string[]): boolean {
    return paths.some((path) => this.router.url.includes(path));
  }

  onNavigationClick(nav: NavigationItem): void {
    if (nav.routes?.length) {
      this.router.navigate([nav.routes[0]]);
    }
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }
}

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;
  private ngUnsubscribe = new Subject();

  constructor(
    public dialogRef: MatDialogRef<MessageModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
      type: string;
      duration: number;
      confirmText?: string;
      cancelText?: string;
    }
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoaded = true;
    }, 100);

    if (this.data.duration !== 0 && this.data.type !== 'warning') {
      const durationTime =
        this.data.duration || this.calculateDuration(this.data.type);
      timer(durationTime * 1000)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.closeModal('cancel');
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next([]);
    this.ngUnsubscribe.complete();
  }

  closeModal(result: string): void {
    this.dialogRef.close(result);
  }

  calculateDuration(type: string): number {
    let duration: number;
    switch (type) {
      case 'success':
        duration = 5;
        break;
      case 'error':
        duration = 10;
        break;
      case 'warning':
        duration = 7;
        break;
      case 'confirmation':
        duration = 0;
        break;
      case 'loading':
        duration = 0;
        break;
      default:
        duration = 30;
    }
    return duration;
  }

  getIconClass(): string {
    switch (this.data.type) {
      case 'success':
        return 'text-green-500';
      case 'error':
        return 'text-red-500';
      case 'warning':
        return 'text-yellow-500';
      case 'confirmation':
        return 'text-blue-500';
      default:
        return 'text-gray-500';
    }
  }

  getIcon(): string {
    switch (this.data.type) {
      case 'success':
        return 'check_circle';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'confirmation':
        return 'help_outline';
      default:
        return 'info';
    }
  }
}

import { CaseConverter } from 'src/app/shared/helpers/case-converter.helper';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormField,
  FormTableStructure,
  TableField,
} from '../../entities/form.entity';
import { UUIDHelper } from '../../helpers/uuid.helper';
import { MatDialog } from '@angular/material/dialog';
import { ExpandibleModalComponent } from '../expandible-modal/expandible-modal.component';

@Component({
  selector: 'app-field-table',
  templateUrl: './field-table.component.html',
  styleUrl: './field-table.component.scss',
})
export class FieldTableComponent {
  @Output() onChanges = new EventEmitter<FormTableStructure>();
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: any;
  }>();
  selectedTab: string = 'columns';
  @Input() fieldId!: string;
  @Input() field!: FormField;
  @Input() table!: FormTableStructure;
  @Input() data!: any;
  @Input() disabled: boolean = false;
  @Input() expandable: boolean = false;
  expectedWidth!: number;
  defaultRowTotals = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['table'] && changes['table'].currentValue) {
      this.table = changes['table'].currentValue;
      this.updateRows();
      this.calculateExpectedWidth();
    }

    if (changes['data'] && changes['data'].currentValue) {
      this.table = {
        ...this.table,
        rows: CaseConverter.keysToCamelCase(changes['data'].currentValue),
      };

      this.table = this.cleanUUIDs(this.table);
      this.cdr.detectChanges();
    }
    if (changes['field'] && changes['field'].currentValue) {
      this.defaultRowTotals = this.field.table?.rows.length || 0;
    }
  }
  deleteRow(index: number): void {
    this.table.rows.splice(index, 1);
    this.emitDataChange();
  }
  cleanUUIDs(data: any): any {
    const removeDashes = (str: string): string =>
      str.replace(/-/g, '').toUpperCase();
    const cleanedFields = data.fields.map((field: any) => ({
      ...field,
      uuid: removeDashes(field.uuid)
    }));

    const cleanedRows = data.rows.map((row: any) => {
      const cleanedRow: any = {};
      Object.keys(row).forEach((key) => {
        cleanedRow[removeDashes(key)] = row[key];
      });
      return cleanedRow;
    });

    const table = {
      fields: cleanedFields,
      rows: cleanedRows,
    };
    return table;
  }
  calculateExpectedWidth() {
    this.expectedWidth = this.table.fields.reduce((acc, field) => {
      const size = field.size ? +field.size : 100;
      return acc + size + 80;
    }, 0);
  }

  selectTab(tabName: string): void {
    this.selectedTab = tabName;
  }

  trackByFn(index: number, item: any): number {
    return item ? item.id : index;
  }

  emitChanges() {
    this.updateRows();
    this.onChanges.emit(this.table);
    if (this.fieldId) {
      const isValid = this.checkIfAnyValueExists(this.table.rows);
      this.onFieldChange.emit({
        fieldId: this.fieldId,
        isValid,
        value: this.table.rows,
      });
    }
    this.cdr.detectChanges();
  }

  checkIfAnyValueExists(items: Array<{ [key: string]: any; }>): boolean {
    for (const item of items) {
      for (const key in item) {
        if (item[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }
  get adjustedWidth(): number {
    return this.field?.metadata?.allowAddRows && this.table.rows.length > this.defaultRowTotals
      ? this.expectedWidth - 20
      : this.expectedWidth;
  }

  emitDataChange(): void {
    this.updateRows();

    this.emitChanges();
  }

  addColumn(): void {
    const columnName = 'column-name';
    this.table.fields.push({
      uuid: UUIDHelper.generateShortUUID(),
      name: columnName,
      editable: true,
      type: 'column',
      sub_fields: [],
    });
    this.updateRows();
    this.emitChanges();
  }

  changeColumnType(index: number, type: 'column' | 'group'): void {
    const field = this.table.fields[index];
    if (type === 'group') {
      field.type = 'group';
      field.sub_fields = [
        {
          uuid: UUIDHelper.generateShortUUID(),
          name: 'sub-column-name',
          editable: true,
          type: 'column',
          sub_fields: [],
        },
      ];
      this.updateRowsForGroup(field.uuid, field.sub_fields);
    } else if (type === 'column') {
      field.type = 'column';
      field.sub_fields = [];
    }
    this.updateRows();
    this.emitChanges();
  }

  updateRowsForGroup(groupKey: string, subFields: TableField[]): void {
    this.table.rows = this.table.rows.map((row) => {
      const newRow = { ...row };
      if (typeof newRow[groupKey] === 'string') {
        newRow[groupKey] = {};
      }
      subFields.forEach((subField) => {
        if (!newRow[groupKey][subField.uuid]) {
          newRow[groupKey][subField.uuid] = '';
        }
      });
      return newRow;
    });
    this.updateRows();
    this.emitChanges();
  }

  addSubColumn(parentIndex: number): void {
    const parent = this.table.fields[parentIndex];
    if (!parent.sub_fields) {
      parent.sub_fields = [];
    }
    parent.sub_fields.push({
      uuid: UUIDHelper.generateShortUUID(),
      name: '',
      editable: true,
      type: 'column',
      sub_fields: [],
    });
    this.updateRowsForGroup(parent.uuid, parent.sub_fields);
    this.updateRows();
    this.emitChanges();
  }

  removeColumn(index: number): void {
    this.table.fields.splice(index, 1);
    this.updateRows();
    this.emitChanges();
  }

  removeSubColumn(parentIndex: number, subIndex: number): void {
    if (
      this.table.fields &&
      this.table.fields[parentIndex] &&
      this.table.fields[parentIndex].sub_fields &&
      this.table.fields[parentIndex].sub_fields.length > subIndex
    ) {
      this.table.fields[parentIndex].sub_fields.splice(subIndex, 1);
      this.updateRows();
      this.emitChanges();
    }
  }

  getTotalColumns(): number {
    let total = 0;
    this.table.fields.forEach((field) => {
      if (field.type === 'group') {
        total += field.sub_fields.length;
      } else {
        total++;
      }
    });
    return total;
  }

  getColumnClass(field: TableField): string {
    if (field.type === 'group') {
      return `col-span-${field.sub_fields.length} flex flex-col items-center`;
    } else {
      return 'flex-1';
    }
  }

  updateRows(): void {
    this.table.rows = this.table.rows.map((row) => {
      const newRow: { [key: string]: any; } = {};
      this.table.fields.forEach((field) => {
        newRow[field.uuid] = field.type === 'group' ? {} : '';

        if (field.type === 'group' && field.sub_fields) {
          field.sub_fields.forEach((subField) => {
            newRow[field.uuid][subField.uuid] =
              row[field.uuid] && row[field.uuid][subField.uuid] !== undefined
                ? row[field.uuid][subField.uuid]
                : '';
          });
        } else {
          newRow[field.uuid] =
            row[field.uuid] !== undefined ? row[field.uuid] : '';
        }
      });
      return newRow;
    });
  }

  addRow(): void {
    const newRow: { [key: string]: any; } = {};
    this.table.fields.forEach((field) => {
      if (field.type === 'group' && field.sub_fields) {
        newRow[field.uuid] = {};
        field.sub_fields.forEach((subField) => {
          newRow[field.uuid][subField.uuid] = '';
        });
      } else {
        newRow[field.uuid] = '';
      }
    });
    this.table.rows.push(newRow);
    this.emitChanges();
  }
  openModal(
    fieldUUID: string,
    row: any,
    columnName: string,
    rowIndex: number
  ): void {
    const currentValue = row[fieldUUID];
    const dialogRef = this.dialog.open(ExpandibleModalComponent, {
      width: '500px',
      data: { columnName, text: currentValue, rowIndex: rowIndex + 1 },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined && result.action === 'update' && result.value) {
        row[fieldUUID] = result.value;
        this.emitDataChange();
      }
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Observable, isObservable, from } from 'rxjs';

@Pipe({
  name: 'resolve'
})
export class ResolvePipe implements PipeTransform {
  transform(value: any): Observable<any> {
    if (isObservable(value)) {
      return value;
    } else if (value instanceof Promise) {
      return from(value);
    } else {
      return from(Promise.resolve(value));
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { lastValueFrom, filter, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UAMAuthService } from '../services/uam-auth.service';
import { decodeJWT } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthHelperService {
  constructor(
    private http: HttpClient,
    private authService: UAMAuthService
  ) { }

  async requestPromise(
    method: string,
    url: string,
    body?: any,
    params?: any
  ): Promise<any> {

      return this.performRequest(
        method,
        url,
        body,
        params
      );
    
  }

  private async performRequestOLD(
    method: string,
    url: string,
    body: any,
    params: any,
    authHeader: string | null,
    onProgress?: (progress: number) => void
  ): Promise<any> {
    const headers = new HttpHeaders({
      ...(body instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' }),
      ...(authHeader ? { Authorization: authHeader } : {}),
    });

    const options = {
      headers,
      params,
      reportProgress: !!onProgress,
      observe: 'events',
      body: body,
    } as any;

    const observable$ = this.http.request(method, url, options);

    return lastValueFrom(
      observable$.pipe(
        filter(
          (event: any) =>
            event.type === HttpEventType.Response ||
            event.type === HttpEventType.UploadProgress ||
            event.type === HttpEventType.DownloadProgress
        ),
        tap((event) => {
          if (
            onProgress &&
            event.total > 0 &&
            (event.type === HttpEventType.UploadProgress ||
              event.type === HttpEventType.DownloadProgress)
          ) {
            onProgress(Math.round((100 * event.loaded) / event.total));
          }
        })
      )
    );
  }
  private async performRequest(
    method: string,
    url: string,
    body: any,
    params: any,
    onProgress?: (progress: number) => void
  ): Promise<any> {
    const headers = new HttpHeaders({
      ...(body instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' })
    });
    const options = {
      headers,
      params,
      reportProgress: !!onProgress,
      observe: 'events',
      body: body,
    } as any;

    const observable$ = this.http.request(method, url, options);

    return lastValueFrom(
      observable$.pipe(
        filter(
          (event: any) =>
            event.type === HttpEventType.Response ||
            event.type === HttpEventType.UploadProgress ||
            event.type === HttpEventType.DownloadProgress
        ),
        tap((event) => {
          if (
            onProgress &&
            event.total > 0 &&
            (event.type === HttpEventType.UploadProgress ||
              event.type === HttpEventType.DownloadProgress)
          ) {
            onProgress(Math.round((100 * event.loaded) / event.total));
          }
        }),
        catchError((error: HttpErrorResponse) => {
          throw error;
        })
      )
    );
  }
  async requestWithProgressPromise(
    method: string,
    url: string,
    onProgress: (progress: number) => void,
    body?: any,
    params?: any
  ): Promise<any> {
    const refreshUrl = [`${environment.refreshEndpoint}`];

    if (refreshUrl.some((u) => url.includes(u))) {
      return this.performRequest(method, url, body, params, onProgress);
    } else {

      return this.performRequest(
        method,
        url,
        body,
        params,
        onProgress
      );
    }
  }
}

<div class="flex flex-col mt-2">
  <label class="block text-sm font-medium text-gray-700 mb-1">Accepted Extensions:</label>
  <div class="flex flex-wrap gap-2 items-center mb-2">
    <span *ngFor="let ext of extensions; let i = index" class="bg-black text-white px-2 py-1 rounded flex items-center">
      {{ ext }}
      <button (click)="removeExtension(i)" class="ml-2 text-sm">
        &times;
      </button>
    </span>

  </div>
  <input [(ngModel)]="currentInput" (keydown.enter)="onEnter(currentInput)" placeholder="Add extension and press Enter"
    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
</div>
<header class="bg-header  dark:bg-dark-header text-white shadow w-full dark:border-dark-header  dark:border-b no-print"
  *ngIf="!isPreviewRoute">
  <div class="flex justify-between items-center h-16 px-4 w-full ">
    <div class="flex flex-shrink-0 flex-row gap-1  rounded-md border-gray-50 ">
      <img class="logo" alt="OE" src="./../../../assets/logos/oe logo.png" (click)="goHome()" priority />
      <div class="title-container" (click)="goHome()">
        <span class="text-lg text-black font-bold">{{ title1 }}</span>
        <span class="sub-title text-black font-medium">{{ title2 }}</span>
      </div>
    </div>

    <div class="ml-4 flex flex-row items-end gap-3 md:ml-6 ">

      <!-- <app-theme-toggle></app-theme-toggle> -->

      <app-select-single *ngIf="isAdminRoute && currentUser && (currentUser.sub?.length || false) && isAdmin"
        [ngModel]="selectedProject" [capitalized]="false" label="Project" [options]="projects" labelKey="project"
        valueKey="project" boxSize="400"
        [class]="'flex w-[300px] h-12 bg-background-alt dark:bg-background-alt rounded-md mx-3'"
        (selectionChange)="onProjectChanged($event)">
        <span select-icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6 mr-2 text-black dar:text-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
          </svg>

        </span>
      </app-select-single>


      <div *ngIf="isAdminRoute" class="relative">
        <button [attr.aria-expanded]="userMenuOpen" aria-haspopup="true" (click)="toggleUserMenu()"
          class="px-1 py-1  bg-background-alt dark:bg-background-alt dark:text-gray-400 text-gray-600 rounded-md focus:outline-none flex items-top justify-between hover:text-gray-900 w-full">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-1">
            <path fill-rule="evenodd"
              d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
              clip-rule="evenodd" />
          </svg>



          <div class="flex-1 min-w-0 flex flex-col mx-2 justify-center items-start"
            *ngIf="currentUser && currentUser.sub && currentUser.sub.length">
            <div class="font-medium text-gray-800 truncate">{{ currentUser.name + ' ' + currentUser.family_name}}</div>
            <div class="text-sm text-black font-light truncate">{{
              currentUser.organization }}</div>
            <div class="text-sm text-black font-light truncate" *ngIf="!currentUser?.organization?.length">{{
              currentUser.email }}</div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'rotate-180': userMenuOpen}"
            class="h-4 w-4 mt-1 transform transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>


        <div *ngIf="userMenuOpen"
          class="absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
          <div class="py-1" role="none">
            <button (click)="logout()" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 logout-btn"
              role="menuitem" tabindex="-1" id="user-menu-item-0">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 inline-block" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H3" />
              </svg>
              Logout
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</header>
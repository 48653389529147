<header class="bg-header  dark:bg-dark-header text-white shadow w-full dark:border-dark-header  dark:border-b no-print"
  *ngIf="!isPreviewRoute">
  <div class="flex justify-between items-center h-16 px-4 w-full ">
    <div class="flex flex-shrink-0 flex-row gap-1  rounded-md border-gray-50 ">
      <img class="logo" alt="OE" src="./../../../assets/logos/oe logo.png" (click)="goHome()" priority />
      <div class="title-container" (click)="goHome()">
        <span class="text-lg text-black font-bold">{{ title1 }}</span>
        <div class="flex flex-row gap-1 items-start">
          <span class="sub-title text-black font-medium">{{ title2 }} </span>
          <span class="text-gray-700 text-xs">{{ version }}</span>
        </div>

      </div>
    </div>

    <div class="ml-4 flex flex-row items-end gap-3 md:ml-6 ">

      <!-- <app-theme-toggle></app-theme-toggle> -->

      <app-select-single *ngIf="isAdminRoute && currentUser && (currentUser.sub?.length || false) && isAdmin"
        [ngModel]="selectedProject" [capitalized]="false" label="Project" [options]="projects" labelKey="project"
        valueKey="project" boxSize="400"
        [class]="'flex w-[300px] h-12 bg-background-alt dark:bg-background-alt rounded-md mx-3'"
        (selectionChange)="onProjectChanged($event)">
        <span select-icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6 mr-2 text-black dar:text-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
          </svg>

        </span>
      </app-select-single>


      <div *ngIf="isAdminRoute" class="relative">


        <button [attr.aria-expanded]="userMenuOpen" aria-haspopup="true" (click)="toggleUserMenu()"
          class="flex items-center justify-between w-full px-3 py-2 space-x-3 text-left transition-colors duration-200 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-background-alt">
          <div class="flex items-center space-x-3">
            <div class="flex-shrink-0">
              <div class="w-10 h-10 bg-black rounded-full flex items-center justify-center">
                <span class="text-lg font-bold text-white">{{ getUserInitials() }}</span>
              </div>
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                {{ currentUser.name || '' }} {{ currentUser.family_name || '' }}
              </p>
              <p class="text-xs text-gray-900 dark:text-gray-400 truncate"
                [ngClass]="{'uppercase': currentUser.organization || false}">
                {{ currentUser.organization || currentUser.email || '' }}
              </p>
            </div>
          </div>
          <lucide-icon name="chevron-down" [ngClass]="{'rotate-180': userMenuOpen}"
            class="w-5 h-5 text-black transition-transform duration-200"></lucide-icon>
        </button>

        <div *ngIf="userMenuOpen"
          class="absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
          <div class="py-1" role="none">
            <button (click)="logout()" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 logout-btn"
              role="menuitem" tabindex="-1" id="user-menu-item-0">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 inline-block" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H3" />
              </svg>
              Logout
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</header>
<div class="modal-header flex items-center space-x-4 px-5 pt-5 bg-white">
  <div class="text-lg font-normal mb-2">Editing: {{ data.columnName }} (Row {{ data.rowIndex }})</div>
</div>

<div class="modal-body px-4 pt-2 pb-4 bg-white">
  <textarea id="expanded-field" [attr.rows]="4" [(ngModel)]="data.text"
    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md resize-none disabled:bg-gray-50">
      </textarea>
</div>


<div class="modal-footer flex justify-end space-x-3 p-5 bg-white ">
  <button class="bg-gray-200 text-gray-800 rounded hover:bg-gray-300 px-5 py-2 focus:outline-none" (click)="close()">
    Cancel
  </button>
  <button class="bg-blue-500 text-white rounded hover:bg-blue-600 px-5 py-2 focus:outline-none"
    (click)="saveAndClose()">
    Save
  </button>
</div>
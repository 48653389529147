import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableField } from '../../entities/form.entity';

@Component({
  selector: 'app-dynamic-table-settings',
  templateUrl: './dynamic-table-settings.component.html',
  styleUrl: './dynamic-table-settings.component.scss'
})
export class DynamicTableSettingsComponent {
  tableField!: TableField;
  index = -1;
  constructor(
    public dialogRef: MatDialogRef<DynamicTableSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.tableField) {
      this.tableField = data.tableField;
      if (!this.tableField.size) {
        this.tableField.size = '100';
      }
    }
    this.index = data.index;
  }

  close(): void {
    this.dialogRef.close({ action: 'close' });
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuid } from 'uuid';
import { FormControl } from '@angular/forms';
import { Tag } from '../../entities/app.entity';

@Component({
  selector: 'app-tags-manager',
  templateUrl: './tags-manager.component.html',
  styleUrl: './tags-manager.component.scss',
})
export class TagsManagerComponent implements OnChanges, OnInit {
  selectedColor = '#FFFFFF';
  newTagControl = new FormControl();
  showColorPicker = false;

  @Input() tags: Tag[] = [];
  @Input() availableTags: Tag[] = [];

  @Output() onAddTag: EventEmitter<Tag> = new EventEmitter<Tag>();
  @Output() onRemoveTag: EventEmitter<Tag> = new EventEmitter<Tag>();

  constructor(private cdr: ChangeDetectorRef) { }
  ngOnInit() {

    this.selectedColor = this.getRandomHexColor();
    this.newTagControl.valueChanges.subscribe((newTag: Tag) => {
      if (newTag && newTag.value && newTag.color) {
        this.addTagByTag({ ...newTag, loading: true });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['tags']) {
      if (changes['tags'].currentValue) {
        this.tags = Array.isArray(changes['tags'].currentValue)
          ? changes['tags'].currentValue
          : [];

        this.tags.forEach((tag) => {
          delete tag.loading;
        });
      }
    }

    if (changes['availableTags']) {
      if (changes['availableTags'].currentValue) {
        this.availableTags = Array.isArray(
          changes['availableTags'].currentValue
        )
          ? changes['availableTags'].currentValue
          : [];
      }
    }
  }
  getAvailableTags() {
    const existingUuids = this.tags.map((tag) => tag.uuid);
    return this.availableTags.filter((availableTag) => {
      const isTagNew = !existingUuids.includes(availableTag.uuid);
      const matchesFilter =
        this.newTagControl && this.newTagControl.value
          ? availableTag.value.includes(this.newTagControl.value)
          : true;
      return isTagNew && matchesFilter;
    });
  }

  addTagByTag(tag: Tag) {
    if (tag && tag.uuid && tag.value.length) {
      this.tags.push(tag);
      this.onAddTag.emit(tag);
      this.selectedColor = this.getRandomHexColor();
      this.newTagControl.setValue('');
      this.cdr.detectChanges();
    }
  }

  addTag() {
    if (this.newTagControl.value && this.selectedColor.length) {
      const tag = {
        uuid: uuid(),
        value: this.newTagControl.value.trim(),
        color: this.selectedColor,
        loading: true,
      };
      this.tags.push(tag);
      this.onAddTag.emit(tag);
      this.newTagControl.reset();
      this.selectedColor = this.getRandomHexColor();
    }
  }

  removeTag(tagToRemove: Tag) {
    tagToRemove.loading = true;
    this.onRemoveTag.emit(tagToRemove);
  }

  toggleColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  colorChanged(color: any) {
    this.selectedColor = color;
    this.showColorPicker = false;
  }
  getRandomHexColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  displayFn(tag: Tag): string {
    return tag && tag.value ? tag.value : '';
  }
}

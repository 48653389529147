import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { AppUserService } from 'src/app/shared/services/app-user.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState, AppUserState } from 'src/app/shared/store/states/app.state';
import { Store } from '@ngrx/store';
import { SubscriptionHelper } from 'src/app/shared/helpers/subscription.helper';
import { selectCurrentUser } from 'src/app/shared/store/selectors/user.selector';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title1 = 'OE-I Fy25 Call';
  title2 = 'For Proposals';
  isDevEnvironment = false;
  unreadNotifications = 6;
  notificationIcon = 'notifications_none';
  isAdminRoute = false;
  isAuthRoute = false;
  isRegistrationRoute = false;
  currentUser!: AppUserState;
  organization = 'PavCon';
  projects: any[] = [];
  selectedProject: string = '';
  userMenuOpen = false;
  isPreviewRoute = false;
  isAdmin = false;
  // localSubArray: Array<Subscription> = [];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private platform: Platform,
    public dialog: MatDialog,
    private appUser: AppUserService,
    private appState: AppStateService,
    private cdr: ChangeDetectorRef,
    private subMgr: SubscriptionHelper,
    private ngxCookieService: CookieService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        const url = event.urlAfterRedirects;
        this.isAdminRoute = url.includes('/admin');
        this.isAuthRoute = url.includes('/auth');
        this.isPreviewRoute = url.includes('previewId=');
        this.isRegistrationRoute = url.includes('/registration');
      });
  }

  ngOnDestroy() {
    this.subMgr.destroy();
  }
  /*

          debounceTime(2000), //This is a temp band aid solution, race condition here.
          take(1)
  */
  ngOnInit(): void {
    this.subMgr.add(
      this.store
        .select(selectCurrentUser)
        .subscribe((currentUser: AppUserState) => {
          if (currentUser.sub !== '') {
            this.currentUser = currentUser;
            if (this.currentUser.role === 'organization-admin') {
              this.isAdmin = true;
            }
            if (this.isAdmin) {
              this.loadProjects(this.currentUser.organization);
            }
          }
        })
    );
  }

  loadProjects(organization: string) {
    if (this.projects.length) return;

    this.appUser
      .getOrganizationProjects(organization)
      .then((projects: any[]) => {
        if (projects) {
          this.projects = projects.map((p) => p.project);
          if (this.projects.length > 0) {
            if (!this.selectedProject.length) {
              this.selectedProject = this.projects[0] as string;
              this.appState.updateSelectedProject(this.selectedProject);
            }
            this.cdr.detectChanges();
          }
        }
      })
      .catch((error) => {
        console.log('error on load projects', error);
      });
  }

  onProjectChanged(project: any) {
    this.selectedProject = project;
    if (this.selectedProject) {
      this.appState.updateSelectedProject(this.selectedProject);
    }
  }

  goHome() {
    if (this.isAdminRoute) {
      this.router.navigateByUrl('/admin');
    } else {
      this.router.navigateByUrl('/');
    }
  }
  get safeAreaHeight() {
    if (!this.platform.isBrowser) {
      return 0;
    }
    alert('is not browser');
    const height = window.innerHeight * window.devicePixelRatio;
    const safeAreaHeight = height - 44;
    return safeAreaHeight;
  }
  openNotifications() {
    this.notificationIcon =
      this.unreadNotifications > 0 ? 'notifications' : 'notifications_none';
    this.router.navigateByUrl('/activity');
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.ngxCookieService.delete(
      'uam-id-token',
      '/',
      environment.cookieInfo.domain
    );
    this.ngxCookieService.delete(
      'uam-refresh-token',
      '/',
      environment.cookieInfo.domain
    );
    this.ngxCookieService.delete(
      'uam-device-key',
      '/',
      environment.cookieInfo.domain
    );
    document.location.href = environment.uamUrl + 'auth/logout';
  }

  selectProject(projectName: string) {
    this.selectedProject = projectName;
  }
  applyProjectSelection() {
    this.appUser.setSelectedProject(this.selectedProject);
  }
  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }
}
